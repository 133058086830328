.loaderWrapper {
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
}

.center {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.ring {
  position: absolute;
  width: clamp(100px, 200px, 16vw);
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  animation: ring 2s linear infinite;
}

/*
    rotating, color change animation
  */
@keyframes ring {
  0% {
    transform: rotate(0deg);
    box-shadow: 1px 5px 2px #0456c8;
    /*     box-shadow: 1px 5px 2px #e65c00; */
  }
  50% {
    transform: rotate(180deg);
    box-shadow: 1px 5px 2px #18b201;
  }
  100% {
    transform: rotate(360deg);
    box-shadow: 1px 5px 2px #0456c8;
  }
}
/*
   outter circular gray ring
  */
.ring:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
}

/*
    loading text
  */
.loadingInfo {
  user-select: none;
  color: #737373;
  font-size: clamp(0.875rem, 1.375rem, 2vw);
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 200px;
  animation: loadingText 1.5s ease-in-out infinite;
}
@keyframes loadingText {
  50% {
    color: #fff;
  }
}
