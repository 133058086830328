.timeInput {
  display: flex;
  flex-direction: row;
  margin: 1.2rem 0;
}

.datePicker {
  display: flex;
  align-items: center;
  margin-right: 0.6rem;
}
.timePicker {
  display: flex;
  align-items: center;
  margin: 0 0.6rem;
}

.tipsInfo {
  margin: 0 1rem;
}
.tipsInfoTime {
  font-size: 1.2rem;
  font-weight: 700;
}
