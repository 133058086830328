.downloadChild {
  margin: 1rem;
  padding: 1.5rem;
  box-shadow: #00000029 0px 1px 4px;
  display: flex;
  background-color: #ffffff;
  flex-direction: column;
}
.verifyEmailInput {
  margin: 1rem;
}
.verifyEmailInput p {
  margin: 0.1rem;
}

.timeHelperText {
  color: #c1151f;
  font-size: small;
}

.multiSelect {
  margin: 1rem;
  display: flex;
}
.multiSelectHelperText {
  margin: 0.5rem 0;
  color: #c1151f;
  font-size: small;
}

.multiInput {
  margin: 1rem;
  display: flex;
  align-items: flex-start;
}
.multiInputTextfield {
  margin: 0 1rem 0 0;
  min-width: 250px;
}
.multiInputTextfield p {
  margin: 0.1rem;
}
.helperText {
  display: flex;
  flex-direction: column;
}
.uploadFileHelperText :not(:first-child) {
  margin-top: 0.6rem;
}
.multiInputChips {
  display: flex;
  flex-wrap: wrap;
  height: 120px;
  /* border: #ccccff solid 1px; */
  /* box-shadow: #00000029 0px 1px 4px; */
  /* padding: 0.5rem; */
  overflow: auto;
}
.multiInputPerChip {
  margin: 0 0.2rem 0.3rem 0;
}

.fileUpload {
  margin: 1rem;
  display: flex;
  align-items: flex-start;
}

.selectField {
  margin-top: 2rem;
}

.singleSelectInput {
  margin: 1rem 0;
  width: 150px;
}

.buttons {
  margin: 2rem 1rem;
  display: flex;
}
.buttons > div {
  margin-right: 1rem;
}

.tabs {
  border-bottom: #757575 1px solid;
}

.tabPanel {
}
