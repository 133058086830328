.clientDetails {
  margin: 1rem;
  padding: 1.5rem;
  box-shadow: #00000029 0px 1px 4px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageChild {
  margin: 2rem 0;
  width: 90%;
}

.header {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
}
