.incrementDecrementButtonGroup {
  display: flex;
  align-items: center;
}

.buttonGroup {
  margin-left: 1rem;
}

.counter {
  display: flex;
  align-items: center;
  padding: 0.2rem;
}
