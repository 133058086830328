.resetPassword {
  margin: 1rem;
  padding: 1.5rem;
  box-shadow: #00000029 0px 1px 4px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageChild {
  margin: 1rem 0;
}
.pageChild > * {
  width: 20rem;
  margin: 0.5rem 0;
}

.header {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
}

.titleDevider {
  align-self: center;
  border-bottom: #bdbdbd 3px solid;
  border-radius: 1px;
  width: 90%;
  margin: 1rem;
}

.password {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttons > * {
  width: auto;
}
