.form {
  background: #fff;
  box-sizing: border-box;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  border-radius: 10px;
}

.textFieldDiv {
  padding: 0.5em;
}

.cognitoPasswordHelperTextList {
  list-style: none;
}
.cognitoPasswordHelperTextList li {
  font-weight: 500;
  margin: 0;
  color: #000;
}
.cognitoPasswordHelperTextList li.neutral::before {
  content: "-";
  padding-right: 0.5em;
  color: #000;
}
.cognitoPasswordHelperTextList li.red::before {
  content: "\2716";
  padding-right: 0.5em;
  color: red;
}

.cognitoPasswordHelperTextList li.red {
  color: red;
}

.cognitoPasswordHelperTextList li.green::before {
  content: "\2713";
  font-weight: 900;
  padding-right: 0.5em;
  color: green;
}
