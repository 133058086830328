.modalContentWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 24px;
  padding: 2em;
}

.wrapper > * {
  padding-block: 0.375em;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 0.5em;
}
