.signup {
  margin: 1rem;
  padding: 1.5rem;
  box-shadow: #00000029 0px 1px 4px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pageChild {
  margin: 1rem auto;
}

.header {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
}

.titleDevider {
  align-self: center;
  border-bottom: #bdbdbd 3px solid;
  border-radius: 1px;
  width: 90%;
  margin: 1rem;
}

.bodyContent {
  /* border: 2px black solid; */
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.textInputFields {
}
.textField {
  margin: 1rem 3rem 1rem 0;
  width: 200%;
}

.password {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.passwordInputFields {
  display: flex;
  justify-content: space-between;
}
.passwordInputFields > * {
  margin: 0 4rem 0 0;
  width: 100%;
}

.select {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.select > * {
}

.modal {
  padding: 1rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.singleSelectInput {
  margin: 1rem 0;
}
