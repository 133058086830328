.groupDetails {
  margin: 1rem;
  padding: 1.5rem;
  box-shadow: #00000029 0px 1px 4px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageChild {
  margin: 1rem 0;
  width: 40rem;
}

.header {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
}

.eachItem {
  margin: 1rem 0;
  min-width: 20rem;
}

.muiButton {
  font-size: 14px;
  margin-top: 8px;
  margin-right: 16px;
  margin-bottom: 8px;
}
