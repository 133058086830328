.not-found {
  width: 560px;
  height: 225px;
  margin-right: -10px;
}
.starry-sky {
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
.search-icon {
  display: inline-block;
}
.notfound-copy {
  color: #fff;
  position: fixed;
  top: 25px;
  right: 10%;
  text-align: right;
}
.h1 {
  font-weight: 700;
  font-size: 40px;
}
.homeLink {
  font-weight: 300;
  color: #fff;
  border-bottom: 1.5px solid #5581d4;
  text-decoration: none;
}
.homeLink:hover {
  font-weight: 300;
  color: #fff;
  border-bottom: 2px solid #fff;
  text-decoration: none;
}
/* change to alternating star opacities */
.all-stars {
  animation: blinkblink 7s linear infinite;
}

@keyframes blinkblink {
  50% {
    opacity: 0.1;
  }
}
/* .moon {
} */
/* input[type="text"] {
  color: #fff;
  background-color: #0a1821;
  padding: 5px;
  border: none;
  border-bottom: 2px solid #ccc;
  font-size: 18px;
}
input[type="text"]:focus {
  border-color: none;
  border-bottom: 2px solid #ccc;
} */
