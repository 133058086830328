.upload {
  margin: 0 0 1rem 0rem;
  display: flex;
  flex-direction: column;
}
.uploadButton {
  margin: 0 1rem 0 0;
  min-width: 270px;
}
.uploadButton p {
  margin: 0.1rem 0.1rem 0 0;
}

.uploadChips {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  margin-top: 1rem;
}
.uploadPerChip {
  margin: 0 0.2rem 0.3rem 0;
}

.helperText {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 0.7rem;
  color: #757575;
}
