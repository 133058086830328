.clientDetails {
  margin: 1rem;
  padding: 1.5rem;
  box-shadow: #00000029 0px 1px 4px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageChild {
  margin: 2rem 0;
}

.generalInfo {
  display: flex;
}

.generalInfoInput {
  margin-right: 2rem;
}

.header {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
}

.groupList {
  margin: 1rem 0;
  min-width: 20rem;
}

.timePickers {
  margin-top: 0.5rem;
  width: fit-content;
}

.oneTimePicker {
  display: flex;
  margin-bottom: 1rem;
  border: #757575 solid 1px;
  padding: 1rem;
}

.helperText {
  display: flex;
  flex-direction: column;
}

.geoInfo {
  display: flex;
  flex-wrap: wrap;
}

.geoInfoInput {
  margin-right: 2rem;
  min-width: 150px;
  margin-bottom: 2rem;
}

.geoFencingHeading {
  margin-bottom: 1.5rem;
}

.geoFences {
  display: flex;
  flex-wrap: wrap;
}

.oneGeoFence {
  display: flex;
  margin-bottom: 1rem;
  margin-right: 2rem;
  border: #757575 solid 1px;
  padding: 1rem;
  min-width: 280px;
}

.geoFenceTextField {
  margin-right: 2rem;
}

.widgetsHeading {
  margin-bottom: 1.5rem;
}

.oneWidget {
  display: flex;
  margin-bottom: 1rem;
  border: #757575 solid 1px;
  padding: 1rem;
}

.widgetTextField {
  width: 100%;
  margin-right: 2rem;
}

.workOrderProfile {
  display: flex;
}

.assignees {
  display: flex;
  flex-direction: column;
}

.oneAssignee {
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: #757575 solid 1px;
  padding: 0.5rem;
  align-items: flex-end;
}

.assigneeInput {
  margin-right: 1rem;
  max-width: 240px;
}

.assigneeDeleteButton {
}

.tabs {
  width: 90%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-bottom: #757575 1px solid;
}

.tabPanel {
  width: 90%;
}

.muiButton {
  font-size: 14px;
  margin-top: 8px;
  margin-right: 16px;
  margin-bottom: 8px;
}
